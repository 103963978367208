

export const BASKET_ACTION_TYPE={
    GET_BASKET_DATA:"GET_BASKET_DATA",
    CREATE_BASKET:"CREATE_BASKET",
    DELETE_BASKET:"DELETE_BASKET"
}


export const PRODUCT_ACTIONS={
    GET_PRODUCTS:"GET_PRODUCTS",
    GET_PRODUCT_DETAILS:"GET_PRODUCT_DETAILS"
}