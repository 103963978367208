 import img1 from './metaImg/animationimg/img1.png'
 import img2 from './metaImg/animationimg/img2.png'
 import img3 from './metaImg/animationimg/img3.png'
 import img4 from './metaImg/animationimg/img4.png'
 import img5 from './metaImg/animationimg/img5.png'
 import img6 from './metaImg/animationimg/img6.png'
 import img7 from './metaImg/animationimg/img7.png'
 import img8 from './metaImg/animationimg/img8.png'
 import img9 from './metaImg/animationimg/img9.png'
 import img10 from './metaImg/animationimg/img10.png'
 import img11 from './metaImg/animationimg/img11.png'
 import img12 from './metaImg/animationimg/img12.png'
 import img13 from './metaImg/animationimg/img13.png'
 import img14 from './metaImg/animationimg/img14.png'
 
 
 
 
 const Data = [
    { css: img1, height: 150,  },
    { css: img2, height: 200,  },
    { css: img3, height: 200,  },
    { css: img4, height: 250,  },
    { css: img5, height: 350,  },
    { css: img6, height: 300,  },
    { css: img7, height: 400,  },
    { css: img8, height: 350,  },
    { css: img9, height: 300,  },
    { css: img10, height: 400,   },
    { css: img11, height: 200,  },
    { css: img12, height: 450,  },
    { css: img13, height: 330,   },
    { css: img14, height: 400,   },
]

export default Data